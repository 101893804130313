import React, { useState } from "react";
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import { useWeb3Modal } from "@web3modal/react";
import { Drawer, Popover } from '@mui/material';
import {
    AccountBalanceWalletOutlined as AccountBalanceWalletOutlinedIcon,
    LoginOutlined as LoginOutlinedIcon
} from '@mui/icons-material';
import { useAccount, useDisconnect } from "wagmi";
import { navbarItems, socialItems } from "../../data";
import menuImg from "../../assets/imgs/menu.svg";
import closeImg from "../../assets/imgs/close.svg";
import arrowDownImg from "../../assets/imgs/arrow-down.svg";

const Navbar = () => {
    const { open, close } = useWeb3Modal();
    const { address, isConnected } = useAccount();
    const { disconnect } = useDisconnect();

    const [openMenu, setOpenMenu] = useState<boolean>(false);
    const [connectWalletAnchorEl, setConnectWalletAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleOpenConnectWallet = (event: React.MouseEvent<HTMLButtonElement>) => {
        setConnectWalletAnchorEl(event.currentTarget);
    };

    const handleCloseConnectWallet = () => {
        setConnectWalletAnchorEl(null);
    };

    const scrollWithOffset = (el: any, offset: any) => {
        const elementPosition = el.offsetTop - offset;
        window.scroll({
            top: elementPosition,
            left: 0,
            behavior: "smooth"
        });
    }

    const openConnectWallet = Boolean(connectWalletAnchorEl);
    const openConnectWalletId = openConnectWallet ? 'connect-wallet-popover' : undefined;

    return (
        <nav className='sticky top-0 right-0 z-30 flex justify-between items-center w-full h-[58px] md:h-[79px] xl:h-[97px] bg-[#251B12]'>
            <div className="w-full h-full p-0 xl:p-[27px_35px_0px_22px]">
                <div className="flex justify-end xl:justify-between items-center">
                    <div className="hidden xl:flex items-center gap-[10px] p-[7.5px_31px] h-[70px]">
                        {
                            navbarItems.map((item, index) => {
                                return (
                                    <HashLink
                                        smooth
                                        key={index}
                                        to={item.to}
                                        className="flex justify-center items-center w-[150px] text-[24px] font-normal leading-[113%]"
                                        scroll={el => scrollWithOffset(el, 110)}
                                    >
                                        {item.title}
                                    </HashLink>
                                )
                            })
                        }
                    </div>

                    <div className="hidden xl:flex justify-end items-center gap-[35px]">
                        <div className="flex justify-center items-center gap-[36px]">
                            {
                                socialItems.map((item, index) => {
                                    return (
                                        <div key={index}>
                                            <a
                                                href={item.href}
                                                target={'_blank'}
                                                rel="noopener noreferrer"
                                            >
                                                <img src={item.icon} alt={item.title} />
                                            </a>
                                        </div>
                                    )
                                })
                            }
                        </div>

                        <div className="flex justify-center items-center w-[180px]">
                            <a
                                href="https://app.uniswap.org/#/swap?outputCurrency=0x09168d55BE879A7f40aCe7fE52B8FD703CDAEf03"
                                target={'_blank'}
                                rel="noopener noreferrer"
                                className="text-[24px] font-medium leading-[113%]">
                                Buy MIDGET
                            </a>
                        </div>

                        <div className="flex justify-center items-center">
                            <button
                                className="flex justify-center items-center gap-[10px] text-[24px] font-medium leading-[113%]"
                                onClick={handleOpenConnectWallet}
                            >
                                <span>
                                    {
                                        isConnected ? (
                                            <span>{address?.slice(0, 4) + "..." + address?.slice(-4)}</span>
                                        ) : (
                                            <span>Connect</span>
                                        )
                                    }
                                </span>

                                <img
                                    src={arrowDownImg}
                                    alt="arrow-down-img"
                                    className={`transition-all ${openConnectWallet ? 'rotate-180 pb-1' : 'rotate-0 pt-1'}`}
                                />
                            </button>

                            <Popover
                                id={openConnectWalletId}
                                open={openConnectWallet}
                                anchorEl={connectWalletAnchorEl}
                                onClose={handleCloseConnectWallet}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                sx={{
                                    zIndex: '999',
                                    '&>.MuiPopover-paper': {
                                        background: 'none',
                                        marginTop: '8px',
                                    }
                                }}
                            >
                                <div className='bg-[#FAEBCB] border border-solid border-[#000] rounded-[15px] text-[24px] text-[#251B12] font-medium'>
                                    <div className={`flex flex-col justify-center items-center gap-[25px] w-[225px] pt-[24px] pb-[35px]`}>
                                        <Link to={'/profile'} className='flex justify-center items-center w-full h-[17px]' onClick={handleCloseConnectWallet}>Profile</Link>
                                        <Link to={'/dao-votes'} className='flex justify-center items-center w-full h-[17px]' onClick={handleCloseConnectWallet}>Dao Votes</Link>
                                        <Link to={'/bandits-list'} className='flex justify-center items-center w-full h-[17px]' onClick={handleCloseConnectWallet}>Bandit's List</Link>
                                        {
                                            isConnected ? (
                                                <button
                                                    className='flex justify-center items-center gap-[5px] w-full leading-[113%]'
                                                    onClick={() => {
                                                        handleCloseConnectWallet();
                                                        open();
                                                    }}
                                                >
                                                    <span>AccountInfo</span>
                                                    <AccountBalanceWalletOutlinedIcon />
                                                </button>
                                            ) : (
                                                <button
                                                    className='flex justify-center items-center gap-[5px] w-full leading-[113%]'
                                                    onClick={() => {
                                                        handleCloseConnectWallet();
                                                        open();
                                                    }}
                                                >
                                                    <span>Connect</span>
                                                    <LoginOutlinedIcon />
                                                </button>
                                            )
                                        }
                                    </div>
                                </div>
                            </Popover>
                        </div>
                    </div>

                    {/* Mobile */}
                    <div className='xl:hidden w-full relative'>
                        <div className="flex justify-end items-center w-full absolute top-[25px] right-[25px]">
                            <button onClick={() => setOpenMenu(true)}>
                                <img src={menuImg} alt="menu-img" className="w-[16px] md:w-[23px]" />
                            </button>
                        </div>

                        <Drawer
                            anchor={'top'}
                            open={openMenu}
                            onClose={() => setOpenMenu(false)}
                            sx={{
                                zIndex: '999',
                                '&>.MuiPaper-root': {
                                    background: '#251B12',
                                }
                            }}
                        >
                            <div className='h-scree'>
                                <div className='flex flex-col items-center h-screen text-[#FFF1CC] relative pt-[39px] md:pt-[78px] pb-[58px] md:pb-[146px]'>
                                    {/* Close button */}
                                    <div className="flex justify-end items-center w-full absolute top-0 left-0 p-[25px]">
                                        <button onClick={() => setOpenMenu(false)}>
                                            <img src={closeImg} alt="close-img" className="w-[28px] h-[28px]" />
                                        </button>
                                    </div>

                                    <div className="w-full">
                                        {/* Connect */}
                                        <div className="w-full">
                                            <button
                                                onClick={() => {
                                                    setOpenMenu(false);
                                                    open();
                                                }}
                                                className="w-full text-center"
                                            >
                                                <h1 className="text-[44px] text-center font-medium leading-[126px]">
                                                    {
                                                        isConnected ? (
                                                            <span>{address?.slice(0, 4) + "..." + address?.slice(-4)}</span>
                                                        ) : (
                                                            <span>Connect</span>
                                                        )
                                                    }
                                                </h1>
                                            </button>
                                        </div>

                                        <div className="flex flex-col justify-center items-center gap-[10px]">
                                            <Link
                                                to={'/profile'}
                                                className='flex justify-center items-center h-[50px] text-[28px] font-normal leading-[113%]'
                                                onClick={() => setOpenMenu(false)}
                                            >
                                                Profile
                                            </Link>

                                            <Link
                                                to={'/dao-votes'}
                                                className='flex justify-center items-center h-[50px] text-[28px] font-normal leading-[113%]'
                                                onClick={() => setOpenMenu(false)}
                                            >
                                                Dao Votes
                                            </Link>

                                            <Link
                                                to={'/bandits-list'}
                                                className='flex justify-center items-center h-[50px] text-[28px] font-normal leading-[113%]'
                                                onClick={() => setOpenMenu(false)}
                                            >
                                                Bandit's List
                                            </Link>
                                        </div>

                                        {/* Buy Midget */}
                                        <div className="w-full">
                                            <a
                                                href="https://app.uniswap.org/#/swap?outputCurrency=0x09168d55BE879A7f40aCe7fE52B8FD703CDAEf03"
                                                target={'_blank'}
                                                rel="noopener noreferrer"
                                            >
                                                <h1 className="text-[44px] text-center font-medium leading-[126px]">Buy MIDGET</h1>
                                            </a>
                                        </div>

                                        <div className="flex flex-col justify-center items-center gap-[10px]">
                                            {
                                                navbarItems.map((item, index) => {
                                                    return (
                                                        <HashLink
                                                            smooth
                                                            key={index}
                                                            to={item.to}
                                                            className="flex justify-center items-center h-[50px] text-[28px] font-normal leading-[113%]"
                                                            scroll={el => scrollWithOffset(el, 80)}
                                                            onClick={() => setOpenMenu(false)}
                                                        >
                                                            {item.title}
                                                        </HashLink>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>

                                    <div className="grow"></div>

                                    <div className="flex justify-center items-center gap-[36px]">
                                        {
                                            socialItems.map((item, index) => {
                                                return (
                                                    <div key={index}>
                                                        <a
                                                            href={item.href}
                                                            target={'_blank'}
                                                            rel="noopener noreferrer"
                                                        >
                                                            <img src={item.icon} alt={item.title} className="w-[33.5px] md:w-[44px]" />
                                                        </a>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </Drawer>
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;