import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { CancelOutlined as CancelOutlinedIcon } from '@mui/icons-material';
import axios from "axios";
import { NFTStorage } from 'nft.storage';
import { useAccount, useContractEvent } from "wagmi";
import { readContract, writeContract, fetchEnsName } from "@wagmi/core";
import { fetchBalance } from "@wagmi/core";
import { useWeb3Modal } from "@web3modal/react";
import { TextField } from '@mui/material';
import {
    REACT_APP_CMC_API_KEY,
    REACT_APP_NFT_STORAGE_API_KEY,
    REACT_APP_TOKEN_CONTRACT_ADDRESS,
    REACT_APP_TOKEN_SUPPLY,
    REACT_APP_VOTING_CONTRACT_ADDRESS
} from "../../config";
import {
    IContractInfo,
    IUserInfo,
} from "../../interfaces";

import wantedTextImg from "../../assets/imgs/wanted-text.svg";
import midgetImg from "../../assets/imgs/midget.webp";
import gunImg from "../../assets/imgs/gun.svg";
import popcornImg from "../../assets/imgs/popcorn.svg";
import numberImg from "../../assets/imgs/number.svg";
import frameImg from "../../assets/imgs/frame.webp";
import union4Img from "../../assets/imgs/union4.svg";
import union5Img from "../../assets/imgs/union5.svg";
import union6Img from "../../assets/imgs/union6.svg";

import votingContractAbi from "../../abis/voting-contract.abi.json";

const Profile = () => {
    const { address, isConnected } = useAccount();
    const { open, close } = useWeb3Modal();

    const [dragActive, setDragActive] = useState<boolean>(false);
    const [isTriggered, setIsTriggered] = useState<boolean>(false);
    const [thumbnail, setThumbnail] = useState();
    const [previewThumbnail, setPreviewThumbnail] = useState<string>();
    const [prevImgWidth, setPrevImgWidth] = useState<number>(0);
    const [prevImgHeight, setPrevImgHeight] = useState<number>(0);
    const [updatingText, setUpdatingText] = useState<string>("");
    const [tokenBalance, setTokenBalance] = useState<number>(0);
    const [tokenPrice, setTokenPrice] = useState<number>(0);
    const [contractInfo, setContractInfo] = useState<IContractInfo>();
    const [ensName, setEnsName] = useState<string>("");
    const [userInfo, setUserInfo] = useState<IUserInfo>();
    const [description, setDescription] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [isUpdating, setIsUpdating] = useState<boolean>(false);

    const initialize = async (isInit: boolean) => {
        try {
            if (isInit) {
                setIsLoading(true);
                document.body.style.overflow = "hidden";
            }

            if (address) {
                try {
                    const ensName = await fetchEnsName({
                        address: address,
                    })

                    setEnsName(ensName as string);
                } catch { }

                const [balance, userInfo] = await Promise.all([
                    fetchBalance({
                        address: address,
                        token: REACT_APP_TOKEN_CONTRACT_ADDRESS,
                    }),
                    readContract({
                        address: REACT_APP_VOTING_CONTRACT_ADDRESS,
                        abi: votingContractAbi,
                        functionName: 'getUserInfo',
                        args: [address],
                        chainId: 5
                    }),
                ])

                const imgUrl = (userInfo as IUserInfo)?.userProfileImg;
                if (imgUrl) {
                    const img: any = await getMeta(imgUrl);
                    setPrevImgWidth(img.naturalWidth);
                    setPrevImgHeight(img.naturalHeight);

                    setPreviewThumbnail(imgUrl);
                }

                setTokenBalance(Number(balance.formatted));
                setUserInfo(userInfo as IUserInfo);

                if (Number(balance.formatted) > 0) {
                    try {
                        const config = {
                            headers: {
                                "X-CMC_PRO_API_KEY": REACT_APP_CMC_API_KEY
                            }
                        }

                        const tokenPriceResult = (await axios.get('/v2/cryptocurrency/quotes/latest?symbol=MIDGET&convert=USD', config)).data;
                        setTokenPrice(tokenPriceResult.data.MIDGET[0].quote.USD.price);
                    } catch (e) {
                        console.log('e', e)
                        setTokenPrice(0);
                    }
                }
            }

            const [contractInfo] = await Promise.all([
                readContract({
                    address: REACT_APP_VOTING_CONTRACT_ADDRESS,
                    abi: votingContractAbi,
                    functionName: 'getContractInfo',
                    chainId: 5
                }),
            ])

            setContractInfo(contractInfo as IContractInfo);
        } catch (e) {
            console.log('e', e);
        }

        setIsLoading(false);
        document.body.style.overflow = "visible";
    }

    const getMeta = (url: string) =>
        new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => resolve(img);
            img.onerror = (err) => reject(err);
            img.src = url;
        });

    const createPoll = async () => {
        try {
            if (!description) {
                return;
            }

            if (!userInfo) {
                toast.error('Please connect wallet.');
                return;
            }

            if (!contractInfo) {
                toast.error('Failed to load data from smart contract.');
                return;
            }

            if (address !== contractInfo?.contractOwner) {
                if (userInfo?.userWeekPollCount >= contractInfo?.maxCreatingCountPerWeek) {
                    toast.error(`You can create proposals only ${contractInfo?.maxCreatingCountPerWeek} ${contractInfo?.maxCreatingCountPerWeek > 1 ? 'times' : 'once'} a week.`);
                    return;
                }
            }

            setIsProcessing(true);

            const tx = await writeContract({
                address: REACT_APP_VOTING_CONTRACT_ADDRESS,
                abi: votingContractAbi,
                functionName: 'createPoll',
                args: [description]
            });

            console.log('tx', tx);

        } catch (e) {
            console.log('e', e);
            setIsProcessing(false);
            toast.error('Failed to create a new proposal.');
        }
    }

    const updateProfileImg = async () => {
        try {
            if (!userInfo) {
                toast.error('Please connect wallet.');
                return;
            }

            setIsUpdating(true);

            const nftStorage = new NFTStorage({ token: REACT_APP_NFT_STORAGE_API_KEY });

            setUpdatingText('Uploading...');
            const result = await nftStorage.store({
                name: "profile image",
                description: "this is a profile image",
                image: thumbnail as any
            });

            const imgUrl = "https://nftstorage.link/ipfs/" + result?.data?.image?.pathname?.split('//')[1]!;

            setUpdatingText('Updating...');
            const tx = await writeContract({
                address: REACT_APP_VOTING_CONTRACT_ADDRESS,
                abi: votingContractAbi,
                functionName: 'updateUserProfileImg',
                args: [imgUrl]
            });

            console.log('tx', tx);

        } catch (e) {
            console.log('e', e);
            setIsUpdating(false);
            toast.error('Failed to update profile image.');
        }
    }

    /* handle file input */
    const inputRef: any = React.useRef(null);

    function handleFile(files: any) {
        setIsTriggered(true);
        setThumbnail(files[0]);
    }

    // handle drag events
    const handleDrag = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === 'dragenter' || e.type === 'dragover') {
            setDragActive(true);
        } else if (e.type === 'dragleave') {
            setDragActive(false);
        }
    };

    // triggers when file is dropped
    const handleDrop = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            handleFile(e.dataTransfer.files);
        }
    };

    // triggers when file is selected with click
    const handleChange = (e: any) => {
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {
            handleFile(e.target.files);
        }
    };

    // triggers the input when the file input div is clicked
    const onFileInputDivClick = () => {
        inputRef.current.click();
    };
    /* ----------------- */

    const unwatch1 = useContractEvent({
        address: REACT_APP_VOTING_CONTRACT_ADDRESS,
        abi: votingContractAbi,
        eventName: 'PollCreated',
        listener(log) {
            console.log(log);
            setIsProcessing(false);
            initialize(false);
            try {
                if (log) {
                    if ((log[0] as any).args.creator == address) {
                        toast.success('Succeeded to create a new proposal.');
                    }
                }
            } catch { }

            if (log.length > 0) unwatch1?.()
        },
    })

    const unwatch2 = useContractEvent({
        address: REACT_APP_VOTING_CONTRACT_ADDRESS,
        abi: votingContractAbi,
        eventName: 'UserProfileImgEdited',
        listener(log) {
            console.log(log);
            setIsUpdating(false);
            setIsTriggered(false);

            try {
                if (log) {
                    if ((log[0] as any).args.user == address) {
                        toast.success('Succeeded to update profile image.');
                    }
                }
            } catch { }

            if (log.length > 0) unwatch2?.()
        },
    })


    useEffect(() => {
        if (!thumbnail) {
            setPreviewThumbnail(undefined);
            return;
        }

        const img: HTMLImageElement = document.createElement("img");
        const objectUrl = URL.createObjectURL(thumbnail);
        img.src = objectUrl;

        img.onload = function () {
            setPrevImgWidth(img.width);
            setPrevImgHeight(img.height);
        };

        setPreviewThumbnail(objectUrl);

        return () => URL.revokeObjectURL(objectUrl);
    }, [thumbnail]);

    useEffect(() => {
        (async () => {
            await initialize(true);
        })();
    }, [address]);

    return (
        <Spin
            spinning={isLoading}
            indicator={<LoadingOutlined className="text-[#FAEBCB]" rev spin />}
            className="!max-h-screen !h-[calc(100vh-58px)] md:!h-[calc(100vh-79px)] xl:!h-[calc(100vh-97px)]"
            size="large"
        >
            <div className="flex flex-col items-center relative">
                {
                    isConnected && (
                        <div className="hidden md:block w-full absolute top-[294px] left-0 z-[-1]">
                            <div className="flex justify-center items-center gap-[55px]">
                                <div className="w-full">
                                    <img src={union4Img} alt="union-img" className="w-full" />
                                </div>
                                <div className="w-full">
                                    <img src={union4Img} alt="union-img" className="w-full rotate-y" />
                                </div>
                            </div>
                        </div>
                    )
                }

                <div className={`flex flex-col items-center w-[332px] h-[488px] md:w-[732px] md:h-[1076px] mt-[34px] md:mt-[109px] bg-[url('/src/assets/imgs/img-border-bg.webp')] bg-center bg-no-repeat bg-cover ${isConnected && tokenBalance > 0 ? 'mb-0' : 'mb-[22px] md:mb-[137px]'}`}>
                    <div className="flex justify-center items-center w-[253px] md:w-[557px] mt-[41px] md:mt-[86px]">
                        <img src={wantedTextImg} alt="wanted-text-img" className="w-full" />
                    </div>

                    <div className={`flex flex-col justify-center items-end gap-0 md:gap-[13px] mt-[34px] md:mt-[66px]`}>
                        {/* Image box */}
                        <div className="flex justify-center items-center w-[165px] h-[165px] md:w-[364px] md:h-[364px] border-[3px] border-solid border-[#C5AB84] relative">
                            {/* File input */}
                            <input ref={inputRef} type="file" onChange={handleChange} className='hidden' />

                            {/* Upload & cancel buttons */}
                            <div className="w-full h-full absolute top-0 left-0">
                                {
                                    !isTriggered ? (
                                        <div
                                            className="w-full h-full cursor-pointer"
                                            onClick={onFileInputDivClick}
                                            onDragEnter={handleDrag}
                                        />
                                    ) : (
                                        <div className="flex justify-center items-center w-full h-full relative bg-[#251B12]/60">
                                            {
                                                !isUpdating ? (
                                                    <>
                                                        <div className='absolute transition-all duration-300 top-[5px] right-[5px] md:top-[10px] md:right-[10px]'>
                                                            <button
                                                                className='flex justify-center items-center w-[20px] h-[20px] rounded-full hover:opacity-80 active:opacity-100'
                                                                onClick={() => {
                                                                    setIsTriggered(false);
                                                                    setThumbnail(undefined);
                                                                    setPreviewThumbnail(undefined);
                                                                }}
                                                            >
                                                                <CancelOutlinedIcon />
                                                            </button>
                                                        </div>

                                                        <button
                                                            className="text-[15px] text-[#FAEBCB] font-medium leading-[113%] border-2 border-solid border-[#FAEBCB] p-[4px_8px] md:p-[6px_12px] hover:opacity-80 active:opacity-100 active:translate-y-1"
                                                            onClick={updateProfileImg}
                                                        >
                                                            Upload
                                                        </button>
                                                    </>
                                                ) : (
                                                    <div className="text-[14px] md:text-[20px] text-[#FAEBCB]">
                                                        {updatingText} <Spin indicator={<LoadingOutlined className="text-[#FAEBCB]" rev spin />} />
                                                    </div>
                                                )
                                            }
                                        </div>
                                    )
                                }
                            </div>

                            {/* Profile image */}
                            {
                                isConnected ? (
                                    previewThumbnail && (
                                        <img src={previewThumbnail} alt="preview-img" className={`${prevImgWidth >= prevImgHeight ? 'w-full' : 'h-full'}`} />
                                    )
                                ) : (
                                    <img src={midgetImg} alt="midget-img" className="w-full" />
                                )
                            }

                            {/* Drag/Drop Event Listener Component */}
                            {
                                dragActive && (
                                    <div
                                        onDragEnter={handleDrag}
                                        onDragLeave={handleDrag}
                                        onDragOver={handleDrag}
                                        onDrop={handleDrop}
                                        className='absolute w-full h-full inset-0'
                                    />
                                )
                            }
                        </div>

                        <div className={`flex justify-center items-center w-full h-[26px] text-[17px] ${isConnected ? 'md:text-[25px]' : 'md:text-[30px]'} text-black font-medium leading-[113px]`}>
                            {
                                isConnected ? (
                                    ensName ? (
                                        ensName
                                    ) : (
                                        address?.slice(0, 4) + '...' + address?.slice(-4)
                                    )
                                ) : (
                                    "The Midget"
                                )
                            }
                        </div>
                    </div>

                    <div className={`flex justify-center items-center relative ${isConnected ? 'mt-[6px] md:mt-[42px]' : 'mt-[28px] md:mt-[75px]'}`}>
                        {
                            !isConnected && (
                                <>
                                    <div className="w-[66px] md:w-[147px] absolute top-[-16px] left-[-35px] md:top-[-32px] md:left-[-75px]">
                                        <img src={gunImg} alt="gun-img" className="w-full" />
                                    </div>

                                    <div className="w-[66px] md:w-[147px] absolute top-[-16px] right-[-35px] md:top-[-32px] md:right-[-75px]">
                                        <img src={gunImg} alt="gun-img" className="w-full rotate-y" />
                                    </div>
                                </>
                            )
                        }

                        <button
                            className={`${isConnected ? 'w-[218px] h-[117px] md:w-[485px] md:h-[259px]' : 'w-[166px] h-[89px] md:w-[365px] md:h-[195px]'} text-[26px] md:text-[49px] text-[#6A4F2E] font-medium leading-[113%] bg-[url('/src/assets/imgs/connect-btn-bg.svg')] bg-center bg-no-repeat bg-cover`}
                            onClick={() => isConnected ? setTokenBalance(tokenBalance + 1) : open()}
                        >
                            {
                                isConnected ? (
                                    <div className="flex flex-col justify-center items-center">
                                        <span className="text-[56px] md:text-[88px] text-[#6A4F2E] font-medium leading-[30px] pb-[5px] md:pb-[20px]">{tokenBalance.toLocaleString()}</span>
                                        <span className="text-[16px] md:text-[33px] text-[#6A4F2E] font-medium">$MIDGET</span>
                                    </div>
                                ) : (
                                    <span>Connect</span>
                                )
                            }
                        </button>
                    </div>

                    {
                        isConnected && tokenPrice > 0 && (
                            <div className="flex justify-center items-center gap-[5px]">
                                <span className="text-[22px] md:text-[33px] text-[#6A4F2E] font-medium">{(tokenPrice * tokenBalance).toLocaleString()}</span>
                                <span className="text-[16px] md:text-[16px] text-[#6A4F2E] font-medium pt-[5px] md:pt-[15px]">U$D</span>
                            </div>
                        )
                    }
                </div>

                {/* Union img */}
                {
                    !isConnected && (
                        <div className="md:hidden w-full">
                            <img src={union6Img} alt="union-img" className="w-full opacity-20 mb-[51px]" />
                        </div>
                    )
                }

                {/* Popcorn */}
                {
                    isConnected && tokenBalance > 0 && tokenBalance < 10 && (
                        <div className="flex flex-col items-center w-full mt-[45px] md:mt-[80px] mb-[125px]">
                            <div className="flex justify-center items-center w-[156px] md:w-[238px]">
                                <img src={popcornImg} alt="popcorn-img" className="w-full" />
                            </div>

                            <div className="text-[64px] font-second font-medium">Popcorn holder</div>

                            <div className="flex flex-col items-center gap-[24px] w-[300px] text-center mt-[25px]">
                                <p className="text-[24px]">You are a $midget holder. The popcorn perk allow you to vote in the Midget DAO.</p>
                                <p className="text-[18px]">1 TOKEN = 1 VOTE</p>
                            </div>
                        </div>
                    )
                }

                {/* Badge */}
                {
                    isConnected && tokenBalance >= 10 && (
                        <div className="flex flex-col items-center w-full mt-[80px] mb-[94px] md:mb-[225px]">
                            <div className="flex justify-center items-center gap-[55px]">
                                <img src={union5Img} alt="union-img" className="hidden md:block w-full" />

                                <div className="flex justify-center items-center min-w-[264px] min-h-[264px] md:min-w-[332px] md:min-h-[332px] relative">
                                    <div className="flex justify-center items-center w-full h-full absolute top-0 left-0">
                                        <img src={numberImg} alt="number-img" />
                                    </div>

                                    <img src={frameImg} alt="frame-img" className="w-full" />
                                </div>

                                <img src={union5Img} alt="union-img" className="hidden md:block w-full rotate-y" />
                            </div>

                            <div className="flex justify-center items-center mt-[25px]">
                                <span className="text-[48px] font-medium">1%</span>
                                <span className="text-[109px] font-second font-medium leading-[103px]">Club</span>
                            </div>

                            <div className="flex flex-col items-center gap-[24px] w-[315px] md:w-[425px] text-center mt-[25px]">
                                <p className="text-[24px]">
                                    You are a 1% $midget holder.<br /> The 1 Badge perk allow you<br /> to make a DAO proposal to be voted on.
                                </p>
                                <p className="text-[18px]">1 badge = 1 proposal per week</p>
                            </div>

                            <div className="flex flex-col justify-center items-center w-full mt-[101px] md:mt-[126px]">
                                <div className="w-full max-w-[1178px] px-[48px]">
                                    <TextField
                                        id="standard-multiline-flexible"
                                        hiddenLabel
                                        multiline
                                        maxRows={4}
                                        variant="standard"
                                        fullWidth={true}
                                        value={description}
                                        disabled={isProcessing}
                                        onChange={(e) => setDescription(e.target.value)}
                                        className="flex justify-center items-center w-full"
                                        placeholder="Type your question here"
                                        sx={{
                                            color: '#FFF1CC',
                                            textAlign: 'center',
                                            '& .MuiInput-root': {
                                                paddingTop: '16px',
                                                paddingBottom: '16px',
                                                '&::before': {
                                                    borderBottom: '1px solid rgba(255, 241, 204, 0.42)'
                                                },
                                                '&:hover': {
                                                    '&::before': {
                                                        borderBottom: '1px solid rgba(255, 241, 204, 0.87) !important'
                                                    },
                                                },
                                                '&.Mui-focused': {
                                                    '&::after': {
                                                        borderBottom: '1px solid rgba(255, 241, 204, 1) !important'
                                                    },
                                                },
                                            },
                                            '& textarea': {
                                                textAlign: 'center',
                                                fontSize: '24px',
                                                color: '#FFF1CC',
                                                lineHeight: '26px'
                                            }
                                        }}
                                    />
                                </div>

                                <p className="text-[15px] text-[#FAEBCB] font-medium leading-[26px]">{`Amount of questions: ${userInfo?.userPollCount || 0}`}</p>

                                <div className="flex justify-center items-center w-full mt-[41px]">
                                    <button
                                        disabled={!description || isProcessing}
                                        className={`${(!description || isProcessing) ? 'opacity-40' : 'active:translate-y-1'} flex justify-center items-center gap-[12px] h-[41px] px-[20px] border border-solid border-[#FFF1CC]`}
                                        onClick={createPoll}
                                    >
                                        <span className="text-[24px] text-[#] font-medium leading-[113%]">Enter</span>
                                        {
                                            isProcessing && (
                                                <Spin indicator={<LoadingOutlined className="text-[#FAEBCB]" rev spin />} />
                                            )
                                        }
                                    </button>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
        </Spin>
    );
};

export default Profile;