import React from "react";
import { TwitterEmbed } from 'react-social-media-embed';
import midgetTransparentImg from "../../assets/imgs/midget-transparent.webp";
import unionImg from "../../assets/imgs/union.svg";
import midgetTextImg from "../../assets/imgs/midget-text.svg";
import fistImg from "../../assets/imgs/fist.webp";
import frameImg from "../../assets/imgs/frame.webp";
import union2Img from "../../assets/imgs/union2.svg";
import union3_1Img from "../../assets/imgs/union3-1.svg";
import union3_2Img from "../../assets/imgs/union3-2.svg";
import union3_3Img from "../../assets/imgs/union3-3.svg";
import cmcImg from "../../assets/imgs/cmc.svg";
import dextoolImg from "../../assets/imgs/dextool.svg";
import uniswapImg from "../../assets/imgs/uniswap.svg";

const Home = () => {
    return (
        <div className="flex flex-col justify-center w-full">
            <div className="flex justify-center w-[324px] md:w-[870px] xl:w-[1012px] mx-auto">
                <img
                    src={midgetTransparentImg}
                    alt="midget-transparent-img"
                    className="w-full"
                />
            </div>

            {/* A Journey of Triumph and Inspiration */}
            <div className="flex justify-center items-center w-full h-[115px] md:h-[324px] xl:h-[441px] mt-0 md:mt-[31px] xl:mt-[51px] relative">
                <div className="flex flex-col justify-center items-center w-full h-full absolute top-0 left-0">
                    <img
                        src={unionImg}
                        alt="union-img"
                        className="h-full"
                    />
                </div>


                <div className="w-[345px] md:w-[869px] xl:w-[1547px]">
                    <h1 className="text-[20px] md:text-[48px] text-center font-bold leading-[27px] md:leading-[64px] tracking-[0.2em] uppercase">
                        A Journey of Triumph and Inspiration
                    </h1>
                </div>
            </div>

            {/* Introduction */}
            <div id="introduction" className="mt-[50px] md:mt-[102px] xl:mt-[21px]">
                <h1 className="text-[45px] md:text-[64px] text-center font-second font-medium leading-[52px] md:leading-[74px]">
                    Introduction
                </h1>
            </div>

            <div className="flex flex-col justify-center items-center gap-[26px] md:gap-[53px] w-[285px] md:w-[699px] xl:w-[1063px] mt-[20px] md:mt-[48px] mx-auto">
                <h1 className="text-[26px] md:text-[40px] text-center font-normal leading-[35px] md:leading-[53px]">
                    In a world gripped by a prohibition on coins and tokens, <br /> a mysterious figure known as MIDGET emerges.
                </h1>

                <div className="text-[20px] md:text-[28px] text-center font-normal leading-[27px] md:leading-[37px]">
                    We are thrilled to unveil The Midget's Secret, an exciting and groundbreaking video series on Twitter driven by a vibrant community powered by Ethereum (ETH). What sets The Midget's Secret apart is that the community holds 100% of the tokens distributed for the series.
                </div>
            </div>

            {/* In Defi we trust */}
            <div id="midget" className="flex flex-col justify-center items-center gap-[30px] md:gap-[45px] w-[312px] md:w-[595px] xl:w-[1062px] mt-[75px] md:mt-[304px] xl:mt-[341px] mx-auto">
                <h1 className="text-[23px] md:text-[31px] font-bold leading-[31px] md:leading-[41px] tracking-[0.2em] uppercase">In Defi we trust</h1>

                <div className="text-[20px] md:text-[28px] text-center font-normal leading-[27px] md:leading-[37px]">
                    The Midget's Secret community comprises passionate individuals who share a common vision for creating captivating video content and promoting inclusivity. Holding 100% of the tokens ensures that the community has a direct stake in the series, enabling active participation in shaping its direction, content, and growth.
                </div>
            </div>

            {/* Midget Text Img */}
            <div className="flex justify-center items-center w-full md:w-[924px] 2xl:w-[1492px] mt-[20px] xl:mt-[40px] mx-auto">
                <img
                    src={midgetTextImg}
                    alt="midget-text-img"
                    className="w-full"
                />
            </div>

            <div className="w-[309px] md:w-[669px] xl:w-[1125px] mt-[66px] md:mt-[85px] xl:mt-[242px] mx-auto">
                <div className="text-[20px] md:text-[28px] text-center font-normal leading-[27px] md:leading-[37px]">
                    By aligning the interests of the community with the success of The Midget's Secret, we create a vibrant ecosystem where participation and engagement are rewarded. As the series gains popularity, the value of the tokens held by the community can appreciate, providing additional incentives for active involvement.
                </div>
            </div>

            <div className="w-[253px] md:w-[537px] xl:w-[635px] mt-[40px] md:mt-[130px] xl:mt-[90px] mx-auto">
                <img
                    src={fistImg}
                    alt="fist-img"
                    className="w-full"
                />
            </div>

            <div className="flex flex-col justify-center items-center gap-[73px] md:gap-[141px] xl:gap-[35px] w-full mt-[138px] md:mt-[153px] xl:mt-[322px]">
                <div className="flex flex-col xl:flex-row justify-center items-center gap-[40px] md:gap-[47px] xl:gap-[268px] w-full">
                    <div className="w-[217px] md:w-[254px] xl:w-[283px] h-[217px] md:h-[254px] xl:h-[283px] relative">
                        <img
                            src={frameImg}
                            alt="frame-img"
                            className="w-full h-full"
                        />

                        <div className="flex flex-col justify-center items-center gap-[10px] w-full h-full absolute top-0 left-0 pb-[20px]">
                            <h5 className="text-[19px] xl:text-[25px] text-center font-semibold leading-[25px] xl:leading-[33px] tracking-[-0.04em]">Total supply</h5>
                            <h3 className="text-[32px] xl:text-[40px] text-center font-semibold leading-[43px] xl:leading-[53px] tracking-[-0.04em]">1089 Tokens</h3>
                        </div>
                    </div>

                    <div className="w-[217px] md:w-[254px] xl:w-[283px] h-[217px] md:h-[254px] xl:h-[283px] relative">
                        <img
                            src={frameImg}
                            alt="frame-img"
                            className="w-full h-full"
                        />

                        <div className="flex flex-col justify-center items-center gap-[10px] w-full h-full absolute top-0 left-0 pb-[20px]">
                            <h5 className="text-[19px] xl:text-[25px] text-center font-semibold leading-[25px] xl:leading-[33px] tracking-[-0.04em]">Name:</h5>
                            <h3 className="text-[32px] xl:text-[40px] text-center font-semibold leading-[43px] xl:leading-[53px] tracking-[-0.04em]">The Midget’s secret</h3>
                        </div>
                    </div>

                    <div className="w-[217px] md:w-[254px] xl:w-[283px] h-[217px] md:h-[254px] xl:h-[283px] relative">
                        <img
                            src={frameImg}
                            alt="frame-img"
                            className="w-full h-full"
                        />

                        <div className="flex flex-col justify-center items-center gap-[10px] w-full h-full absolute top-0 left-0 pb-[20px]">
                            <h5 className="text-[19px] xl:text-[25px] text-center font-semibold leading-[25px] xl:leading-[33px] tracking-[-0.04em]">Ticker:</h5>
                            <h3 className="text-[32px] xl:text-[40px] text-center font-semibold leading-[43px] xl:leading-[53px] tracking-[-0.04em]">MIDGET</h3>
                        </div>
                    </div>
                </div>

                <div id="tokenomics" className="flex flex-col md:flex-row justify-between items-center gap-[10px] md:gap-0 w-full">
                    <div className="flex justify-start items-center w-full md:w-fit">
                        <img
                            src={union2Img}
                            alt="union-img"
                            className="w-[241px] md:w-[345px] xl:w-[580px]"
                        />
                    </div>

                    <div className="flex flex-col items-center text-[24px] xl:text-[30px] text-center font-semibold leading-[42px] xl:leading-[40px] tracking-[-0.04em]">
                        <p>FAIR TOKENOMICS</p>
                        <p>100% UNISWAP LP BURN</p>
                        <p>0% Team tokens</p>
                        <p>CA RENOUNCED</p>
                        <p>TAX 3/3</p>
                    </div>

                    <div className="flex justify-end items-center w-full md:w-fit">
                        <img
                            src={union2Img}
                            alt="union-img"
                            className="w-[241px] md:w-[345px] xl:w-[580px] rotate-180 md:rotate-y"
                        />
                    </div>
                </div>
            </div>

            <div className="w-full text-[20px] md:text-[28px] xl:text-[34px] text-center leading-[27xp] md:leading-[37px] xl:leading-[45px] break-words px-[20px] mt-[20px]">
                <a
                    href={"https://etherscan.io/token/0x09168d55be879a7f40ace7fe52b8fd703cdaef03#tokenInfo"}
                    target={'_blank'}
                    rel="noopener noreferrer"
                >
                    0x09168d55BE879A7f40aCe7fE52B8FD703CDAEf03
                </a>
            </div>

            <div className="flex flex-col md:flex-row justify-center items-start md:items-center gap-[20px] md:gap-[90px] xl:gap-[150px] w-fit md:w-full mt-[50px] md:mt-[75px] xl:mt-[120px] mx-auto">
                <div className="w-[250px] md:w-[290px] xl:w-[470px]">
                    <a
                        href={"https://coinmarketcap.com/currencies/the-midgets-secret/"}
                        target={'_blank'}
                        rel="noopener noreferrer"
                    >
                        <img
                            src={cmcImg}
                            alt="cmc-img"
                            className="w-full"
                        />
                    </a>
                </div>

                <div className="w-[130px] md:w-[162px] xl:w-[263px]">
                    <a
                        href={"https://www.dextools.io/app/en/ether/pair-explorer/0x09168d55BE879A7f40aCe7fE52B8FD703CDAEf03"}
                        target={'_blank'}
                        rel="noopener noreferrer"
                    >
                        <img
                            src={dextoolImg}
                            alt="dextool-img"
                            className="w-full"
                        />
                    </a>
                </div>

                <div className="w-[178px] md:w-[207px] xl:w-[337px]">
                    <a
                        href={"https://app.uniswap.org/#/swap?inputCurrency=0x09168d55BE879A7f40aCe7fE52B8FD703CDAEf03"}
                        target={'_blank'}
                        rel="noopener noreferrer"
                    >
                        <img
                            src={uniswapImg}
                            alt="uniswap-img"
                            className="w-full"
                        />
                    </a>
                </div>
            </div>

            <div className="flex flex-col xl:flex-row justify-between items-center gap-[40px] md:gap-[113px] xl:gap-0 w-full mt-[60px] md:mt-[92px] xl:mt-[165px]">
                <div className="hidden xl:block">
                    <img
                        src={union3_1Img}
                        alt="union-img"
                        className="w-full"
                    />
                </div>

                <div className="flex flex-col items-center w-[269px] md:w-[671px] xl:w-[1063px] text-[20px] md:text-[28px] xl:text-[34px] text-center leading-[27xp] md:leading-[37px] xl:leading-[45px]">
                    We invite you to join The Midget's Secret community, become a part of this Ethereum-based revolution in content creation for Twitter and other social platforms, and contribute to the growth and success of our exciting video series. Together, we can unlock the true potential of decentralized communities and redefine the way video content is produced and enjoyed.
                </div>

                <div className="hidden xl:block">
                    <img
                        src={union3_1Img}
                        alt="union-img"
                        className="w-full rotate-180"
                    />
                </div>

                <div className="flex xl:hidden justify-center md:justify-between items-center w-full">
                    <div className="hidden md:block">
                        <img
                            src={union3_2Img}
                            alt="union-img"
                            className="w-full"
                        />
                    </div>

                    <div className="hidden md:block">
                        <img
                            src={union3_2Img}
                            alt="union-img"
                            className="w-full rotate-180"
                        />
                    </div>

                    <div className="md:hidden w-full">
                        <img
                            src={union3_3Img}
                            alt="union-img"
                            className="w-full"
                        />
                    </div>
                </div>
            </div>

            {/* Disclaimer */}
            <div className="flex flex-col justify-center items-center gap-[30px] md:gap-[45px] w-[312px] md:w-[595px] xl:w-[1062px] mt-[60px] md:mt-[92px] xl:mt-[165px] mx-auto">
                <h1 className="text-[23px] md:text-[31px] font-bold leading-[31px] md:leading-[41px] tracking-[0.2em] uppercase">Disclaimer</h1>

                <div className="text-[20px] md:text-[28px] text-center font-normal leading-[27px] md:leading-[37px]">
                    The information provided herein regarding the $MIDGET token is for informational purposes only. It does not constitute legal, financial, or other professional advice and is not intended to be used as a basis for making investment decisions. The purchase of cryptocurrency, including $MIDGET, involves significant risk and potential for financial loss. You should consult with a qualified professional for advice before making any investment. Cryptocurrency value can be extremely volatile and unpredictable. As such, you should only invest money that you can afford to lose. Past performance is not indicative of future results. You should also be aware that the regulatory landscape for cryptocurrencies can change rapidly, potentially impacting the value and legality of your investment. By reading this, you agree that we are not liable for any damages caused by your use of this information, whether they are direct, indirect, incidental, special, or consequential.
                </div>
            </div>

            <div className="flex justify-center w-full max-w-[550px] px-[20px] mt-[60px] md:mt-[92px] xl:mt-[165px] mb-[80px] md:mb-[112px] xl:mb-[185px] mx-auto">
                <TwitterEmbed url='https://twitter.com/midgeteth/status/1671556761826865155' width={'100%'} />
            </div>
        </div >
    );
};

export default Home;