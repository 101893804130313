import React, { useEffect, useState } from "react";
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { readContract } from "@wagmi/core";
import { REACT_APP_TOKEN_CONTRACT_ADDRESS } from "../../config";
import { ITokenHolder } from "../../interfaces";

import wantedTextImg from "../../assets/imgs/wanted-text.svg";
import gunImg from "../../assets/imgs/gun.svg";
import bulletMarksImg from "../../assets/imgs/bullet-marks.svg";
import union6Img from "../../assets/imgs/union6.svg";

import tokenContractAbi from "../../abis/token-contract.abi.json";

const BanditsList = () => {
    const [tokenHolders, setTokenHolders] = useState<ITokenHolder[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const initialize = async () => {
        try {
            setIsLoading(true);

            const [tokenHolders] = await Promise.all([
                readContract({
                    address: REACT_APP_TOKEN_CONTRACT_ADDRESS,
                    abi: tokenContractAbi,
                    functionName: 'getHolders',
                    chainId: 5
                })
            ])

            setTokenHolders(tokenHolders as ITokenHolder[]);
        } catch (e) {
            console.log('e', e);
        }

        setIsLoading(false);
    }

    useEffect(() => {
        (async () => {
            await initialize();
        })();
    }, []);

    return (
        <div className="flex flex-col items-center relative">
            <div className={`flex flex-col items-center w-[332px] h-[488px] md:w-[733px] md:h-[935px] mt-[34px] md:mt-[109px] bg-[url('/src/assets/imgs/img-border-bg.webp')] md:bg-[url('/src/assets/imgs/bandists-border-bg.webp')] bg-center bg-no-repeat bg-cover mb-[22px] md:mb-[137px]`}>
                <div className="flex justify-center items-center w-[253px] md:w-[557px] mt-[41px] md:mt-[86px]">
                    <img src={wantedTextImg} alt="wanted-text-img" className="w-full" />
                </div>

                <div className="flex justify-between w-full px-[40px]">
                    <div className="w-[80px] md:w-[184px]">
                        <img src={gunImg} alt="gun-img" className="w-full rotate-[20deg]" />
                    </div>

                    <div className="relative">
                        <div className="absolute top-[5px] left-[-10px]">
                            <img src={bulletMarksImg} alt="bullet-marks-img" className="w-[20px] md:w-full" />
                        </div>

                        <div className="text-[50px] md:text-[128px] text-[#6A4F2E] font-second font-medium translate-y-[-15px]">List</div>
                    </div>
                </div>

                <div className="flex flex-col justify-center items-center gap-[10px] md:gap-[32px] w-full px-[30px] md:px-[77.5px]">
                    <h1 className="text-[20px] md:text-[32px] text-[#6A4F2E] font-medium leading-[113%]">Bandits</h1>

                    <div className="flex flex-col items-center w-full h-[220px] md:h-[335px] overflow-auto no-scrollbar">
                        {
                            !isLoading ? (
                                tokenHolders.filter(x => x.balance > 0).length > 0 ? (
                                    tokenHolders.filter(x => x.balance > 0 && (x.holder !== "0x365B2a58EBFf019bbD67fCc77acfA5c5F11728C5" && x.holder !== "0x09168d55BE879A7f40aCe7fE52B8FD703CDAEf03"))
                                        .map((item, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    className="flex justify-between items-center w-full h-[16px] md:h-[27px] border-b border-solid border-[#6A4F2E]"
                                                >
                                                    <div className="flex justify-center items-center text-[14px] md:text-[24px] text-[#6A4F2E] font-medium">
                                                        <span>#</span>
                                                        <span>{index + 1}</span>
                                                    </div>

                                                    <div className="text-[8px] md:text-[15px] text-[#6A4F2E] font-medium leading-[113%]">{item.holder}</div>
                                                </div>
                                            );
                                        })
                                ) : (
                                    <div className="text-[14px] md:text-[20px] text-[#6A4F2E]/80">
                                        No items
                                    </div>
                                )
                            ) : (
                                <div className="text-[14px] md:text-[20px] text-[#6A4F2E]">
                                    Loading... <Spin indicator={<LoadingOutlined className="text-[#6A4F2E]" rev spin />} />
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>

            <div className="md:hidden w-full">
                <img src={union6Img} alt="union-img" className="w-full opacity-20 mb-[51px]" />
            </div>
        </div>
    );
};

export default BanditsList;