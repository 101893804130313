import React, { Fragment, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useAccount, useContractEvent } from "wagmi";
import { fetchBalance } from "@wagmi/core";
import { readContract, writeContract } from "@wagmi/core";
import { IContractInfo, IUserInfo, IVote } from "../../interfaces";
import { REACT_APP_TOKEN_CONTRACT_ADDRESS, REACT_APP_VOTING_CONTRACT_ADDRESS } from "../../config";

import fistImg from "../../assets/imgs/fist.webp";
import union4Img from "../../assets/imgs/union4.svg";
import union7Img from "../../assets/imgs/union7.svg";

import votingContractAbi from "../../abis/voting-contract.abi.json";

const DaoVotes = () => {
    const { address, isConnected } = useAccount();

    const [tokenBalance, setTokenBalance] = useState<number>(0);
    const [contractInfo, setContractInfo] = useState<IContractInfo>();
    const [userInfo, setUserInfo] = useState<IUserInfo>();
    const [votes, setVotes] = useState<IVote[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    const initialize = async (isInit: boolean) => {
        try {
            if (isInit) {
                setIsLoading(true);
            }

            if (address) {
                const [balance, userInfo] = await Promise.all([
                    fetchBalance({
                        address: address,
                        token: REACT_APP_TOKEN_CONTRACT_ADDRESS,
                    }),
                    readContract({
                        address: REACT_APP_VOTING_CONTRACT_ADDRESS,
                        abi: votingContractAbi,
                        functionName: 'getUserInfo',
                        args: [address],
                        chainId: 5
                    }),
                ])

                setTokenBalance(Number(balance.formatted));
                setUserInfo(userInfo as IUserInfo);
            }

            const [contractInfo, votes] = await Promise.all([
                readContract({
                    address: REACT_APP_VOTING_CONTRACT_ADDRESS,
                    abi: votingContractAbi,
                    functionName: 'getContractInfo',
                    chainId: 5
                }),
                readContract({
                    address: REACT_APP_VOTING_CONTRACT_ADDRESS,
                    abi: votingContractAbi,
                    functionName: 'getPolls',
                    args: [0, 1000, true],
                    chainId: 5
                }),
            ])

            setContractInfo(contractInfo as IContractInfo);
            setVotes(votes as IVote[]);
        } catch (e) {
            console.log('e', e);
        }

        setIsLoading(false);
    }

    const castVote = async (pollId: number, vote: boolean) => {
        try {
            if (!userInfo) {
                toast.error('Please connect wallet.');
                return;
            }

            if (!contractInfo) {
                toast.error('Failed to load data from smart contract.');
                return;
            }

            if (address !== contractInfo?.contractOwner) {
                if (tokenBalance == 0) {
                    toast.error(`You have no token to vote.`);
                    return;
                }

                if (Math.floor(tokenBalance) <= userInfo?.userVoteCount) {
                    toast.error(`Insufficient token balance.`);
                    return;
                }
            }

            setIsProcessing(true);

            const tx = await writeContract({
                address: REACT_APP_VOTING_CONTRACT_ADDRESS,
                abi: votingContractAbi,
                functionName: 'castVote',
                args: [pollId, vote]
            });

            console.log('tx', tx);
        } catch (e) {
            console.log('e', e);
            setIsProcessing(false);
            toast.error('Failed to vote.');
        }
    }

    const unwatch = useContractEvent({
        address: REACT_APP_VOTING_CONTRACT_ADDRESS,
        abi: votingContractAbi,
        eventName: 'VoteCasted',
        listener(log) {
            console.log(log);
            setIsProcessing(false);
            initialize(false);

            try {
                if (log) {
                    if ((log[0] as any).args.voter == address) {
                        toast.success('Succeeded to vote.');
                    }
                }
            } catch { }

            if (log.length > 0) unwatch?.()
        },
    })

    useEffect(() => {
        (async () => {
            await initialize(true);
        })();
    }, [address]);

    return (
        <div className="flex flex-col items-center relative">
            <div className="w-full absolute top-[135px] md:top-[41px] left-0">
                <div className="hidden md:flex justify-center items-center gap-[216px]">
                    <div className="w-full">
                        <img src={union4Img} alt="union-img" className="w-full" />
                    </div>
                    <div className="w-full">
                        <img src={union4Img} alt="union-img" className="w-full rotate-y" />
                    </div>
                </div>

                <div className="flex md:hidden justify-center items-center w-full">
                    <img src={union7Img} alt="union-img" className="w-full" />
                </div>
            </div>

            <div className="flex flex-col justify-center items-center w-[162px] mt-[50px] md:mt-[86px]">
                <img src={fistImg} alt="fist-img" className="w-full" />
            </div>

            <h1 className="text-[48px] md:text-[94px] font-extrabold leading-[64px] md:leading-normal mt-[24px] md:mt-0">DAO Votes</h1>

            <div className="flex flex-col justify-center items-center mt-[165px] md:mt-[41px] mb-[185px] md:mb-[91px]">
                {
                    !isLoading ? (
                        votes.filter(x => x.isValid).length > 0 ? (
                            votes.filter(x => x.isValid).map((item, index) => {
                                return (
                                    <Fragment key={index}>
                                        <div className="flex flex-col justify-center items-center">
                                            <div className="text-[20px] md:text-[31px] font-extrabold leading-normal">{`QUESTION #${index + 1}`}</div>
                                            <div
                                                className="text-[20px] md:text-[34px] font-normal leading-normal max-w-[1200px] px-[30px] mt-[9px]"
                                                dangerouslySetInnerHTML={{ __html: item.description.replaceAll('\n', '<br />') }}
                                            />
                                            <div className="flex justify-center items-center gap-[60px] mt-[40px] md:mt-[32px] relative">
                                                {
                                                    isProcessing && (
                                                        <div className="flex justify-center items-center w-full h-full absolute pb-[40px]">
                                                            <Spin indicator={<LoadingOutlined className="text-[#FAEBCB]" rev spin />} />
                                                        </div>
                                                    )
                                                }

                                                <div className="flex flex-col justify-center items-center gap-[20px] md:gap-[13px]">
                                                    <button
                                                        disabled={item.voters.includes(address || "") || isProcessing}
                                                        className={`${(item.voters.includes(address || "") || isProcessing) ? 'opacity-40' : 'active:translate-y-1'} flex justify-center items-center w-[105px] h-[105px] border-2 border-solid border-[#FAEBCB] rounded-full`}
                                                        onClick={() => castVote(item.pollId, true)}
                                                    >
                                                        <span className="text-[24px] font-normal leading-normal">YES</span>
                                                    </button>

                                                    <div className="text-[24px] font-normal leading-normal">{`${item.yeaVotes} votes`}</div>
                                                </div>

                                                <div className="flex flex-col justify-center items-center gap-[20px] md:gap-[13px]">
                                                    <button
                                                        disabled={item.voters.includes(address || "") || isProcessing}
                                                        className={`${(item.voters.includes(address || "") || isProcessing) ? 'opacity-40' : 'active:translate-y-1'} flex justify-center items-center w-[105px] h-[105px] border-2 border-solid border-[#FAEBCB] rounded-full`}
                                                        onClick={() => castVote(item.pollId, false)}
                                                    >
                                                        <span className="text-[24px] font-normal leading-normal">NO</span>
                                                    </button>

                                                    <div className="text-[24px] font-normal leading-normal">{`${item.nayVotes} votes`}</div>
                                                </div>
                                            </div>
                                        </div>

                                        {
                                            index < votes.length - 1 && (
                                                <hr className="w-[270px] border-[#FFF1CC] border-t-[6px] border-dotted mt-[57px] mb-[53px]" />
                                            )
                                        }
                                    </Fragment>
                                );
                            })
                        ) : (
                            <div className="text-[20px] md:text-[26px] text-[#FAEBCB]/80">
                                No items
                            </div>
                        )
                    ) : (
                        <div className="text-[20px] md:text-[26px]">
                            Loading... <Spin indicator={<LoadingOutlined className="text-[#FAEBCB]" rev spin />} />
                        </div>
                    )
                }
            </div>
        </div>
    );
};

export default DaoVotes;