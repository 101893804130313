import React from "react";
import footerTextImg from "../../assets/imgs/footer-text.svg";
import { footerSocialItems } from "../../data";

const Footer = () => {
    return (
        <footer className="flex flex-col justify-center items-center w-full h-[283px] py-[52px] bg-[#FFF1CC]">
            <div>
                <img
                    src={footerTextImg}
                    alt="footer-text"
                    className="w-[343px] md:w-full"
                />
            </div>

            <p className="text-[16px] text-[#251B12] font-semibold leading-[21px] tracking-[-0.04em] my-[22px]">
                © 2023  The Midget’s Secret - All Rights Reserved
            </p>

            <div className="flex justify-center items-center gap-[36px] w-full mt-[36px]">
                {
                    footerSocialItems.map((item, index) => {
                        return (
                            <div key={index}>
                                <a
                                    href={item.href}
                                    target={'_blank'}
                                    rel="noopener noreferrer"
                                >
                                    <img src={item.icon} alt={item.title} />
                                </a>
                            </div>
                        )
                    })
                }
            </div>
        </footer>
    );
};

export default Footer;