import React, { useEffect } from 'react';
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  Outlet,
  useLocation
} from "react-router-dom";
import { FloatButton } from "antd";
import { ToastContainer } from "react-toastify";
import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum'
import { Web3Modal } from '@web3modal/react'
import { configureChains, createConfig, WagmiConfig } from 'wagmi';
import { mainnet, goerli, bscTestnet } from 'wagmi/chains';
import { alchemyProvider } from '@wagmi/core/providers/alchemy'
import { publicProvider } from 'wagmi/providers/public';

import Footer from './components/Footer';
import Home from './components/Home';
import Profile from './components/Profile';
import DaoVotes from './components/DaoVotes';
import BanditsList from './components/BanditsList';
import Navbar from './components/Navbar';
import { REACT_APP_ENABLE_TESTNETS } from './config';

function App() {

  const chains = [
    mainnet,
    ...(REACT_APP_ENABLE_TESTNETS ? [goerli] : []),
  ];

  const projectId = '831b533baeb3a3d3d6e19f30fcd89502'

  const { publicClient, webSocketPublicClient } = configureChains(
    chains,
    [
      w3mProvider({ projectId }),
      alchemyProvider({ apiKey: 'Qf7Uf65tLcGFNiYHyRh9Tc-qpVwHlfuG' }),
      alchemyProvider({ apiKey: 'ECTz4C-mND37A79lyXwlUFJCSZ3QXlky' }),
      publicProvider()
    ]
  );

  const defaultChain = REACT_APP_ENABLE_TESTNETS ? goerli : mainnet

  const wagmiConfig = createConfig({
    autoConnect: true,
    connectors: w3mConnectors({ projectId, chains }),
    publicClient,
    webSocketPublicClient,
  });
  const ethereumClient = new EthereumClient(wagmiConfig, chains)

  const AppLayout = () => {

    const location = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [location]);

    return (
      <div className='flex flex-col min-h-screen'>
        <Navbar />
        <main className='grow'>
          <Outlet />
        </main>
        <Footer />

        <FloatButton.BackTop />
      </div>
    )
  };

  return (
    <div className="App">
      <WagmiConfig config={wagmiConfig}>
        <BrowserRouter>
          <Routes>
            <Route path="*" element={<Navigate to="/" />} />
            <Route path="/" element={<AppLayout />} >
              <Route path="/" element={<Home />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/dao-votes" element={<DaoVotes />} />
              <Route path="/bandits-list" element={<BanditsList />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </WagmiConfig>

      <Web3Modal
        themeMode='light'
        projectId={projectId}
        ethereumClient={ethereumClient}
        defaultChain={defaultChain}
      />

      <ToastContainer autoClose={1500} hideProgressBar={true} />
    </div>
  );
}

export default App;
