import telegramIcon from "../assets/imgs/telegram.svg";
import twitterIcon from "../assets/imgs/twitter.svg";
import discordIcon from "../assets/imgs/discord.svg";
import tiktokIcon from "../assets/imgs/tiktok.svg";
import instagramIcon from "../assets/imgs/instagram.svg";
import telegramIconColored from "../assets/imgs/telegram-colored.svg";
import twitterIconColored from "../assets/imgs/twitter-colored.svg";
import discordIconColored from "../assets/imgs/discord-colored.svg";
import tiktokIconColored from "../assets/imgs/tiktok-colored.svg";
import instagramIconColored from "../assets/imgs/instagram-colored.svg";

export const navbarItems = [
    {
        id: 1,
        title: 'Introduction',
        to: '/#introduction'
    },
    {
        id: 2,
        title: 'MIDGET',
        to: '/#midget'
    },
    // {
    //     id: 3,
    //     title: 'Storytelling',
    //     to: '/storytelling'
    // },
    {
        id: 4,
        title: 'Tokenomics',
        to: '/#tokenomics'
    },
];

export const socialItems = [
    {
        id: 1,
        title: 'telegram',
        icon: telegramIcon,
        href: 'https://t.me/midgeteth',
    },
    {
        id: 2,
        title: 'twitter',
        icon: twitterIcon,
        href: 'https://twitter.com/midgeteth?s=21&t=xM_qJvUkmd4FC9TSQtCvxQ',
    },
    {
        id: 3,
        title: 'discord',
        icon: discordIcon,
        href: '/',
    },
    {
        id: 4,
        title: 'tiktok',
        icon: tiktokIcon,
        href: 'https://www.tiktok.com/@midgeteth',
    },
    {
        id: 5,
        title: 'instagram',
        icon: instagramIcon,
        href: 'https://instagram.com/ethmidget',
    },
];

export const footerSocialItems = [
    {
        id: 1,
        title: 'telegram',
        icon: telegramIconColored,
        href: 'https://t.me/midgeteth',
    },
    {
        id: 2,
        title: 'twitter',
        icon: twitterIconColored,
        href: 'https://twitter.com/midgeteth?s=21&t=xM_qJvUkmd4FC9TSQtCvxQ',
    },
    {
        id: 3,
        title: 'discord',
        icon: discordIconColored,
        href: '/',
    },
    {
        id: 4,
        title: 'tiktok',
        icon: tiktokIconColored,
        href: 'https://www.tiktok.com/@midgeteth',
    },
    {
        id: 5,
        title: 'instagram',
        icon: instagramIconColored,
        href: 'https://instagram.com/ethmidget',
    },
];